import deepmerge from 'deepmerge';
import type { Config, DeepPartial } from '../default/types';
import defaultConfig, { getFullLocaleFromConfig, environment, customArrayMerge } from '../default';
import { productConfig } from './product/product';
import { installmentCalculatorConfig } from './product/installmentCalculator';

const tenantId = 'quellede';

const config = deepmerge<Config, DeepPartial<Config>>(
  defaultConfig,
  {
    host: { name: tenantId, domain: 'quelle.de' },
    clientId: 'QuelleDe',
    i18n: {
      defaultLocale: 'de-DE',
      locales: ['de-DE'],
      languages: ['de'],
    },
    tenantId,
    company: {
      name: 'Quelle',
      seoTitleName: 'quelle.de',
    },
    navigation: {
      firstLevelFontSize: '1rem',
    },
    headerSlots: {
      serviceApi: `https://storage.googleapis.com/drupal-pages/${environment}/menus/${tenantId}/<locale>/bucket-service-flyout.json`,
    },
    assets: {
      cmsImage: {
        imageOptimizationEnabled: true,
        uriTransformEndpoint: `https://storage.googleapis.com/drupal-images-${tenantId}/$1/`,
      },
      assetServerHost: 'https://bilder.quelle.de',
    },
    staticContent: {
      apiEndpoints: {
        webappJson: `https://cms.${tenantId}.empirie.<envTld>/<locale>/<path>?_format=webapp_json&flat`,
        bucket: `https://storage.googleapis.com/drupal-pages/${environment}<subfolder>/${tenantId}/<locale>/<identifier>.json`,
      },
      ssg: {
        prerenderAtBuildTime: [
          { params: { cmsPage: ['storefront'] }, locale: 'de' },
          { params: { cmsPage: ['service-hilfe'] }, locale: 'de' },
        ],
      },
    },
    product: productConfig,
    installmentCalculator: installmentCalculatorConfig,
    search: {
      adServer: {
        mode: 'containerOnly',
      },
      hideTelekomIcon: true,
    },
    order: {
      assets: {
        premium: {
          getPremiumImage: ({ device }: { device: string }) =>
            `https://bilder.quelle.de/empiriecom/io/quellede/de/BS3_CheckOut_${device}.jpg`,
          getPremiumIcon: () =>
            'https://bilder.quelle.de/empiriecom/io/quellede/de/premiumicon_1.svg',
        },
        gifs: {
          success: {
            url: 'https://bilder.quelle.de/i/empiriecom/io/de/thank_you_1_transparent.gif',
          },
        },
      },
      premium: { enabled: false },
      hasFlexikontoAdvantageClub: true,
      hasCo2Compensation: true,
    },
    footer: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-pages/${environment}<subfolder>/${tenantId}/<locale>/<identifier>.json`,
      },
      ssg: {
        prerenderAtBuildTime: [
          { params: { type: ['full'] }, locale: 'de' },
          { params: { type: ['socket'] }, locale: 'de' },
        ],
      },
    },
    forms: {
      contact: {
        selectOptionsSubject: [
          { label: 'forms.type.subject.option.articleError', value: 'articleError' },
          { label: 'forms.type.subject.option.changed.email', value: 'emailChanged' },
          { label: 'forms.type.subject.option.changed.data', value: 'dataChanged' },
          { label: 'forms.type.subject.option.account', value: 'account' },
          { label: 'forms.type.subject.option.retoure', value: 'retoure' },
          { label: 'forms.type.subject.option.product', value: 'product' },
          { label: 'forms.type.subject.option.delivery', value: 'delivery' },
          { label: 'forms.type.subject.option.web', value: 'web' },
          { label: 'forms.type.subject.option.complaint.comment', value: 'complaint' },
          { label: 'forms.type.subject.option.return.data', value: 'return' },
          { label: 'forms.type.subject.option.coupons', value: 'coupons' },
          { label: 'forms.type.subject.option.newsletter', value: 'newsletter' },
          { label: 'forms.type.subject.option.other', value: 'other' },
        ],
      },
      newsletterUnsubscribe: {
        useDedicatedSubmitButtonTitleFor: ['unsubscribe|99', 'unsubscribeFlexikonto|98'],
        fields: [
          {
            id: 'newsletter',
            formType: 'radio',
            defaultValue: 'unsubscribe|99',
            options: [
              {
                id: 'frq-daily',
                value: 'frequency|0',
                messageKey: 'forms.newsletter.unsubscribe.type.frequency.option.daily',
              },
              {
                id: 'frq-weekly',
                value: 'frequency|1',
                messageKey: 'forms.newsletter.unsubscribe.type.frequency.option.weekly',
              },
              {
                id: 'unsubscribe',
                value: 'unsubscribe|99',
                messageKey: 'forms.newsletter.unsubscribe.type.unsubscribe',
              },
              {
                id: 'unsubscribe-flexikonto',
                value: 'unsubscribeFlexikonto|98',
                messageKey: 'forms.newsletter.unsubscribe.type.unsubscribeFlexikonto',
              },
              {
                id: 'pause',
                value: 'pause|-100',
                messageKey: 'forms.newsletter.unsubscribe.type.pause',
              },
            ],
            additionalForm: {
              id: 'pause',
              formType: 'select',
              showOnlyWhenWhatSelected: 'pause',
              messageKeyPlaceholder: 'forms.newsletter.unsubscribe.type.pause.label',
              options: [
                {
                  id: 'pause-7',
                  value: '7',
                  messageKey: 'forms.newsletter.unsubscribe.type.pause.option.7',
                },
                {
                  id: 'pause-14',
                  value: '14',
                  messageKey: 'forms.newsletter.unsubscribe.type.pause.option.14',
                },
                {
                  id: 'pause-21',
                  value: '21',
                  messageKey: 'forms.newsletter.unsubscribe.type.pause.option.21',
                },
                {
                  id: 'pause-30',
                  value: '30',
                  messageKey: 'forms.newsletter.unsubscribe.type.pause.option.30',
                },
              ],
            },
          },
          { id: 'email', formType: 'email' },
        ],
      },
      newsletterSubscribe: {
        de: {
          unsubscribeLink:
            'https://www.quelle.de/service-hilfe/newsletter/pausieren-oder-abmelden/',
          voucherLink: 'https://www.quelle.de/service-hilfe/gutscheine-rabatte/',
        },
      },
    },
    banner: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<locale>/<identifier>.json`,
      },
    },
    nlSheetTexts: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<locale>/nl-sheet.json`,
      },
    },
    serviceLayer: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<locale>/<identifier>.json`,
        bucketPages: `https://storage.googleapis.com/drupal-pages/${environment}/${tenantId}/<locale>/<identifier>.json`,
      },
    },
    seoContent: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/inspire-seofiles/<locale>_${tenantId}-<category>.json`,
      },
    },
    survey: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-surveys/${environment}/${tenantId}/<locale>/<identifier>.json`,
      },
    },
    promotionBanner: {
      apiEndpoints: {
        bucketEmptyPromotions: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<language>/empty-promotion.json`,
        bucketPromotion: `https://storage.googleapis.com/promotion-data/${tenantId}/<language>/<code>.json`,
        bucketContentSnippet: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<language>/<identifier>.json`,
      },
    },
    voucher: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/promotion-data/${tenantId}/<locale>/<identifier>.json`,
      },
    },
    appBanner: { appUrl: 'https://app.adjust.com/uvh953o_lmcyu9b' },
    tracking: {
      soastaId: '38EQD-2PT6K-8M2C6-RPG2A-MLT97',
      tagmanagerId: 'GTM-PWVG2XP',
      googleSiteVerificationID: ['CKrb18LGJ__0NQAvYMPOHWM1bPfUEtU6vN14Ji8gVQs'],
      msvalidateID: '4067315BE5669CD6C74BDEC6F6AC5D47',
      schemaOrgOrganisation: {
        'de-DE': {
          addressCountry: 'DE',
          addressRegion: 'BY',
          addressLocality: 'Burgkunstadt',
          alternateName: ['Quelle Versandhandel', 'Quelleversand'],
          areaServed: 'DE',
          contactEmail: 'internet@quelle.de',
          contactUrl: 'https://www.quelle.de/service-hilfe/beratung',
          description:
            'Quelle - Wir liefern Lösungen. Quelle Online Shop: Elektrogeräte, Technik, Möbel und vieles mehr günstig online bestellen.',
          email: 'service@quelle.de',
          hoursAvailable: ['Mo-Su 7:00-22:00'],
          legalName: 'Quelle GmbH',
          logo: `/assets/logos/${tenantId}/logo.svg`,
          name: 'Quelle',
          productSupported:
            '(Festnetz 20 Cent/Verbindung inkl. MwSt., Mobilfunk max. 60 Cent/Verbindung inkl. MwSt.)',
          sameAs: [
            'https://www.facebook.com/Quelle/',
            'https://www.quelle.de/blog/',
            'https://de.wikipedia.org/wiki/Quelle_(Versandhandel)',
          ],
          telephone: '+43-9572-3868-411',
          url: 'https://www.quelle.de',
          postalCode: '96224',
          privacyNoticePath: '/service-hilfe/ueber-uns/datenschutz',
          potentialAction: {
            target: 'https://www.quelle.de/s/',
          },
          streetAddress: 'Bahnhofstraße 10',
        },
      },
    },
    externalScript: {
      kuecheCo: {
        key: 'pAYrueynYRcqna7lGLoVT39kF83Wy3PYENp81Yw0QJqeTyzAjXvc1FwGOzCm',
      },
    },
    headerInformation: {
      de: {
        description:
          'Der Sommer 2020 im Quelle Online Shop: Elektrogeräte, Technik, Möbel und vieles mehr günstig online bestellen ✔ bequeme Ratenzahlung ✔ Gratis Versand ab 75 €',
        title: 'Quelle.de | Elektrogeräte, Möbel und Technik online kaufen',
        canonical: 'https://www.quelle.de',
        robots: 'index,follow',
      },
    },
    // kim is renamed as "customerCentricFeedback"
    customerCentricFeedback: {
      tenantId: '1600',
    },
    dynamicYieldTracking: {
      key: 'a7f5b2847df3d945cc4379520ec60d993f82965e5a43bc03045c1850f3adf1b5',
    },
    geoCountryLayer: {
      enabled: true,
      flag: 'de',
      alternatives: [
        {
          flag: 'ch',
          shops: [{ link: 'https://www.ackermann.ch' }],
        },
        {
          flag: 'at',
          shops: [{ link: 'https://www.universal.at' }],
        },
      ],
    },
    bookings: {
      overview: {
        showOpenPayment: 'sum-and-open',
      },
    },
  },
  { arrayMerge: customArrayMerge },
);

export const getFullLocale = (localeOrLanguage: string | undefined) =>
  getFullLocaleFromConfig(localeOrLanguage, config);

// eslint-disable-next-line import/no-default-export
export default config;
