import type { ProductConfig } from '../../default/product/product.types';
import type { DeepPartial } from '../../default/types';

export const productConfig: DeepPartial<ProductConfig> = {
  cliplister: {
    customerId: '157281',
  },
  hasCarbonHint: false,
  powerSupply: {
    histrogram: {
      included: 'https://bilder.quelle.de/i/empiriecom/FuAG_included.svg',
      notIncluded: 'https://bilder.quelle.de/i/empiriecom/FuAG_not_included.svg',
      specification: 'https://bilder.quelle.de/i/empiriecom/FuAG_specifications.svg',
    },
  },
};
